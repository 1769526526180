<template>
  <div class="page">
    <div class="head common-backbar" v-if="!isReset">
      <div class="back" @click="navigation.smartBack('/profile')"><Icon icon="arrow-left" /> back</div>
    </div>

    <div class="content">
      <Icon icon="password" class="bgIcon" />
      <h1 v-if="!isReset">Change password</h1>
      <h1 v-if="isReset">Reset password</h1>
      <form v-on:submit.prevent="reset" class="common-form" :class="{ 'common-form-loading': busy }">
        <div class="form-content" :class="{ 'common-disabled': busy }">
          <component :is="f.component" v-for="(f, k) in fields" :key="f.key || k" v-bind="f.preset" v-model="f.value" />
          <transition name="maxH">
            <div class="common-form-error" v-if="formError" v-t="formError" />
          </transition>
          <transition name="maxH">
            <div class="common-form-error" v-if="canSubmit && !samePassword">
              Confirmation password doesn't match
            </div>
          </transition>

          <div class="form-actions">
            <Button v-bind="Functions.Presets.Button.Submit" text="Reset" :disabled="!canSubmit" @click="submit" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { computed, markRaw } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useNavigation } from "@/components/renderless/navigation";
import { useForm } from "@/components/renderless/form";
import Functions from "@/functions";
import Button from "@/components/atoms/Button.vue";
import Input from "@/components/atoms/Input.vue";
import Icon from "@/components/atoms/Icon.vue";
export default {
  name: "ChangePassword",
  components: {
    Button,
    Input,
    Icon
  },
  props: {
    token: { type: String, default: null }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const navigation = useNavigation();

    const isReset = computed(() => {
      return props.token;
    });

    const fields = [];
    if (!isReset) {
      fields.push({
        key: "oldPassword",
        component: markRaw(Input),
        preset: Object.assign({ ...Functions.Presets.Field.Password }, { label: "Current password" }),
        value: ""
      });
    }
    fields.push({
      key: "newPassword",
      component: markRaw(Input),
      preset: Object.assign({ ...Functions.Presets.Field.Password }, { label: "New password" }),
      value: ""
    });
    fields.push({
      key: "confirmNewPassword",
      component: markRaw(Input),
      preset: Object.assign({ ...Functions.Presets.Field.Password }, { label: "Confirm new password" }),
      value: ""
    });
    const formData = useForm(fields);

    const submit = () => {
      if (!formData.canSubmit.value && samePassword) {
        return;
      }
      formData.formError.value = "";
      formData.busy.value = true;
      let action = isReset.value ? "User/resetPassword" : "User/changePassword";
      let data = formData.currentData.value;
      if (isReset.value) {
        data["email"] = "";
        data["token"] = props.token;
      }
      store
        .dispatch(action, data)
        .then(
          () => {
            router.push({ path: isReset.value ? "/reset-password-success" : "/profile" });
          },
          () => {
            formData.formError.value = "common.validation.generic";
            formData.busy.value = false;
          }
        )
        .finally(() => {
          formData.busy.value = false;
        });
    };

    const samePassword = computed(() => {
      let nP = formData.fields.value.find(f => f.key == "newPassword");
      let cnP = formData.fields.value.find(f => f.key == "confirmNewPassword");
      return nP.value == cnP.value;
    });

    return {
      Functions,
      navigation,
      isReset,
      busy: formData.busy,
      formError: formData.formError,
      canSubmit: formData.canSubmit,
      currentData: formData.currentData,
      fields: formData.fields,
      isFormValid: formData.isFormValid,
      submit,
      samePassword
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  @include flexCentered(column);

  .head {
    flex-grow: 0;
    width: 100%;
  }

  .content {
    margin: auto 0;
    @include maxWidth($size-breakpoint-mobile);

    .bgIcon {
      position: absolute;
      color: $color-blue;
      width: 100%;
      height: 100%;
      overflow: hidden;
      opacity: 0.15;
      left: 0;
      top: 0;
      filter: blur(10px);
      pointer-events: none;
    }
  }

  .form-content {
    :deep(input) {
      @include standardShadow;
    }
  }
}
</style>
