<template>
  <div></div>
</template>
<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  name: "Logout",
  setup() {
    const store = useStore();
    const router = useRouter();
    onMounted(() => {
      store.dispatch("User/logout").finally(() => {
        router.push("/login").catch(() => {});
      });
    });
  }
};
</script>
