<template>
  <div class="pill" :class="classObject">
    <slot></slot>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  name: "Pill",
  props: {
    color: { type: String, default: "white" }
  },
  setup(props) {
    const classObject = computed(() => {
      return {
        ["pill--color-" + props.color]: !!props.color
      };
    });

    return {
      classObject
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.pill {
  display: inline-block;
  @include edge(5px);
  padding: addSpace(1);

  &--color-blue {
    color: $color-white;
    background: $color-blue;
  }
  &--color-success {
    background: $color-success;
  }
  &--color-warning {
    background: $color-warning;
  }
}
</style>
