<template>
  <div class="register">
    <div class="content">
      <h1><img alt="Soptee" src="@/assets/logo/spotee-min.svg" /> Forgot Password</h1>
      <form v-on:submit.prevent="reset" class="common-form" :class="{ 'common-form-loading': busy }">
        <div class="form-content" :class="{ 'common-disabled': busy }">
          <component :is="f.component" v-for="(f, k) in fields" :key="f.key || k" v-bind="f.preset" v-model="f.value" />
          <transition name="maxH">
            <div class="common-form-error" v-if="formError" v-t="formError" />
          </transition>

          Lux:<br />
          <Lux />
          <Lux :score="0" />
          <Lux :score="1" />
          <Lux :score="2" />
          <Lux :score="3" />
          <Lux :score="4" />
          <Lux :score="5" />
          <Lux :score="6" />
          <Lux :score="7" />
          <Lux :score="8" />
          <Lux :score="9" />
          <Lux :score="10" />

          LuxCoin:<br />
          <LuxCoin />
          <LuxCoin :score="0" />
          <LuxCoin :score="1" />
          <LuxCoin :score="2" />
          <LuxCoin :score="3" />
          <LuxCoin :score="4" />
          <LuxCoin :score="5" />
          <LuxCoin :score="6" />
          <LuxCoin :score="7" />
          <LuxCoin :score="8" />
          <LuxCoin :score="9" />
          <LuxCoin :score="10" />
          <br />
          <br />
          <div class="form-actions">
            <Button v-bind="Functions.Presets.Button.Submit" text="Reset" :disabled="!canSubmit" @click="reset" />
            <Button v-bind="Functions.Presets.Button.Link" text="login" to="/login" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { markRaw } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Functions from "@/functions";
import { useForm } from "@/components/renderless/form";
import Button from "@/components/atoms/Button.vue";
import Input from "@/components/atoms/Input.vue";
import Lux from "@/components/atoms/Lux.vue";
import LuxCoin from "@/components/atoms/LuxCoin.vue";
export default {
  name: "ForgotPassword",
  components: {
    Button,
    Input,
    Lux,
    LuxCoin
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const formData = useForm([
      {
        key: "email",
        component: markRaw(Input),
        preset: Functions.Presets.Field.Email,
        value: ""
      }
    ]);
    const reset = () => {
      if (!formData.canSubmit.value) {
        return;
      }
      formData.formError.value = "";
      formData.busy.value = true;
      store
        .dispatch("User/resetPasswordRequest", formData.currentData.value)
        .then(
          () => {
            router.push({ path: "/forgot-password-success" });
          },
          () => {
            formData.formError.value = "common.validation.generic";
            formData.busy.value = false;
          }
        )
        .finally(() => {
          formData.busy.value = false;
        });
    };
    return {
      Functions,
      busy: formData.busy,
      formError: formData.formError,
      canSubmit: formData.canSubmit,
      currentData: formData.currentData,
      fields: formData.fields,
      isFormValid: formData.isFormValid,
      reset
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.register {
  height: 100%;
  @include flexCentered(column);
  @include golfBackground;

  .content {
    z-index: z("front");
    @include maxWidth($size-breakpoint-mobile);
    text-align: left;
    padding: addSpace(6) addSpace(4);

    h1 {
      font-weight: 300;
      margin: addSpace(-10) 0 addSpace(12) 0;
      img {
        position: relative;
        top: 50px;
        height: 120px;
      }
    }

    .form-content {
      :deep(input) {
        @include standardShadow;
      }
    }

    .form-actions {
      @include flexCentered();
      .button + .button {
        margin-left: auto;
      }
    }
  }
}
</style>
