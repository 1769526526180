<template>
  <div class="disclaimer">
    <a href="https://spotee.com.au/financial-services-guide" target="_blank">Financial Services Guide</a> -
    <a @click="$router.push('/terms')">Terms of Use</a> -
    <a href="https://spotee.com.au/privacy-policy" target="_blank">Privacy and collection policy</a>
  </div>
</template>

<script>
export default {
  name: "Disclaimer"
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.disclaimer {
  text-align: right;
  padding: addSpace(2) addSpace(4);
  color: $color-blue;
  background: $color-almost-white;

  @include mq($to: phablet) {
    @include flexCentered(column);
    align-items: flex-start;
    font-size: 0.1rem;
  }

  a {
    font-size: 1rem;
    color: inherit;
    font-size: $text-small;
    font-style: italic;
    text-decoration: underline;
    margin: addSpace(1) 0;
  }
}
</style>
