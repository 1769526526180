<template>
  <div class="page">
    <div class="user">
      <Icon class="profile" icon="profile" />
      Edit
    </div>

    <div class="content">
      <form v-on:submit.prevent="editField" class="common-form" :class="{ 'common-form-loading': busy }">
        <div class="form-content" :class="{ 'common-disabled': busy }">
          <component :is="f.component" v-for="(f, k) in fields" :key="f.key || k" v-bind="f.preset" v-model="f.value" />

          <transition name="maxH">
            <div class="common-form-error" v-if="formError" v-t="formError" />
          </transition>

          <div class="form-actions">
            <Button v-bind="Functions.Presets.Button.HollowGray" text="Cancel" to="/profile" />
            <Button v-bind="Functions.Presets.Button.Submit" text="Save" :disabled="!canSubmit" @click="editField" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { markRaw } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Functions from "@/functions";
import { useForm } from "@/components/renderless/form";
import Button from "@/components/atoms/Button.vue";
import Icon from "@/components/atoms/Icon.vue";
import Input from "@/components/atoms/Input.vue";
export default {
  name: "EditProfile",
  props: {
    field: { type: String, default: null }
  },
  components: {
    Button,
    Icon
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const fieldMap = [
      {
        key: "firstName",
        component: markRaw(Input),
        preset: Object.assign({ ...Functions.Presets.Field.Field }, { label: "Name" }),
        value: store.getters["User/userDataValue"]("firstName") || ""
      },
      {
        key: "displayName",
        component: markRaw(Input),
        preset: {
          ...Functions.Presets.Field.DisplayName,
          validation: v => {
            if (v.length < 2) {
              return false;
            } else {
              return store.dispatch("User/checkDisplayNameInUse", formData.currentData.value).then(
                response => {
                  return response.data.inUse ? "common.validation.nickname_in_use" : true;
                },
                () => {
                  return "common.validation.generic";
                }
              );
            }
          }
        },
        value: store.getters["User/userDataValue"]("displayName") || ""
      },
      {
        key: "phone",
        component: markRaw(Input),
        preset: Functions.Presets.Field.Mobile,
        value: store.getters["User/userDataValue"]("phone") || ""
      }
    ];
    const currentField =
      fieldMap.find(f => {
        return f.key == props.field;
      }) || {};
    const formData = useForm([currentField]);

    const editField = () => {
      store.dispatch("User/updateDetails", formData.currentData.value).finally(() => {
        router.push("/profile");
      });
    };

    return {
      Functions,
      busy: formData.busy,
      formError: formData.formError,
      canSubmit: formData.canSubmit,
      currentData: formData.currentData,
      fields: formData.fields,
      isFormValid: formData.isFormValid,
      editField
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  @include flexCentered(column);
  justify-content: flex-start;
  padding: 0 addSpace(2) addSpace(2) addSpace(2);

  .user {
    @include maxWidth($size-breakpoint-phablet);
    color: $color-blue;
    background: rgba($color-blue, 0.1);
    margin-bottom: addSpace(4);
    border-bottom-right-radius: 30px;
    font-size: $text-lead;
    font-weight: 600;
    .icon {
      width: 15rem;
      height: 15rem;
      opacity: 0.15;
      margin: -1rem -4rem -2rem -3rem;
    }
  }

  .content {
    @include flexCentered;
    @include maxWidth($size-breakpoint-phablet);
    justify-content: flex-start;
    gap: addSpace(2);
    padding: addSpace(6) addSpace(4);
  }

  .form-content {
    :deep(input) {
      @include standardShadow;
    }
  }

  .form-actions {
    @include flexCentered();
    justify-content: flex-end;
    .button + .button {
      margin-left: addSpace(2);
    }
  }
}
</style>
