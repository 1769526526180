<template>
  <div class="page">
    <div class="content">
      <LoadingBar
        class="stripePaymentsLoading"
        v-if="$store.getters['StripePayment/busy']"
        :loading="$store.getters['StripePayment/busy']"
        :transparent="true"
      />

      <transition name="maxH">
        <div class="result" v-if="isSuccess">
          <h1>Thank You!</h1>
          <p>
            Your payment has been confirmed.<br />
            Now connect with Spotee and start shining the light on your shares.
          </p>
          <Button
            v-bind="Functions.Presets.Button.Full"
            text="Enter"
            icon="arrow-right"
            iconPosition="right"
            :to="$store.getters['User/roleHome']"
          />
        </div>
      </transition>

      <transition name="maxH">
        <div class="result" v-if="isFail">
          <h1>Sorry</h1>
          <p>
            Unfortunately, we’ve encountered an issue processing your card.<br />
            Please check your details and try again.<br />
            We look forward to helping you soon.
          </p>
          <Button v-bind="Functions.Presets.Button.Full" text="Re-enter card details" @click="checkout" />
          <div class="actions">
            <span class="or">or navigate</span>
            <Button v-bind="Functions.Presets.Button.InlineLink" text="back to login" to="/logout" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useNavigation } from "@/components/renderless/navigation";
import Functions from "@/functions";
import Button from "@/components/atoms/Button.vue";
import LoadingBar from "@/components/atoms/LoadingBar.vue";
export default {
  name: "StripePayments",
  props: {
    result: { type: String, default: null }
  },
  components: {
    Button,
    LoadingBar
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const navigation = useNavigation();

    const isSuccess = computed(() => {
      return !store.getters["StripePayment/busy"] && props.result == "CheckoutSuccess";
    });

    const isFail = computed(() => {
      return !store.getters["StripePayment/busy"] && props.result == "CheckoutCancel";
    });

    const checkout = () => {
      store.dispatch("StripePayment/Checkout").then(res => {
        if (res.data.checkoutUrl) {
          window.location = res.data.checkoutUrl;
        }
      });
    };

    const confirm = () => {
      store
        .dispatch("StripePayment/CheckoutCallback", {
          result: props.result,
          reference: route.query.reference
        })
        .then(() => {
          return store.dispatch("User/refreshLogin");
        });
    };

    onMounted(() => {
      confirm();
    });

    return {
      Functions,
      navigation,
      isSuccess,
      isFail,
      checkout
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  @include flexCentered(column);
  @include golfBackground;

  .content {
    z-index: z("front");
    @include maxWidth($size-breakpoint-mobile);
    text-align: left;
    padding: addSpace(6) addSpace(4);

    .stripePaymentLoading {
      margin: 50% 10%;
      width: 80%;
    }

    .actions {
      @include flexCentered;

      .or {
        margin: addSpace(2);
      }
    }
  }
}
</style>
