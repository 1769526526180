<template>
  <div class="lux" :class="{ zero: validScore == 0 }" :style="cssVars">
    <div class="score" v-text="displayScore"></div>
    <div class="glow"></div>
    <div class="ellipse" :class="coinScore"></div>
  </div>
</template>

<script>
import { computed } from "vue";
import Functions from "@/functions";
export default {
  name: "LuxCoin",
  props: {
    score: { type: [String, Number], default: 0 },
    size: { type: [String, Number], default: "2em" }
  },
  setup(props) {
    const validScore = computed(() => {
      return Math.max(Math.min(parseFloat(props.score) || 0, 10), 0);
    });
    const displayScore = computed(() => {
      return validScore.value ? Functions.Formatting.addCommas(validScore.value, 0) : "-";
    });
    const coinScore = computed(() => {
      return "score" + Math.round(validScore.value);
    });
    const shading = computed(() => {
      return parseFloat(validScore.value) || 0;
    });
    const cssVars = computed(() => {
      return {
        "--score": validScore.value,
        "--size": props.size,
        "--score-border": validScore.value / 96 + "em",
        "--opacity": validScore.value / 10
      };
    });

    return {
      validScore,
      displayScore,
      coinScore,
      shading,
      cssVars
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

$color-light-off: darken($color-error, 45%);
$color-light-on: lighten($color-light, 5%);

.lux {
  @include transition;
  @include flexCentered;
  width: var(--size);
  height: var(--size);
  position: relative;
  color: lighten($color-black, 20%);

  .score {
    @include transition;
    position: relative;
    z-index: z("front");
    font-size: 1.25em;
    font-weight: 400;
    text-shadow: 0 1px 1px rgba($color-gray, 0.5);
  }

  .glow {
    @include transition;
    position: absolute;
    z-index: z("flat");
    width: 100%;
    height: 80%;
    top: 0;
    background-image: linear-gradient(rgba($color-light, 0), rgba($color-light, 0.4));
    opacity: var(--opacity);
  }

  .ellipse {
    @include transition;
    position: absolute;
    z-index: z("flat");
    height: 26%;
    width: 100%;
    border-radius: 50%;
    left: 0;
    bottom: 5%;
    border-bottom: var(--score-border) solid rgba($color-black, 0.3);

    &.score {
      &0 {
        background-color: $color-light-gray;
      }
      &1 {
        background-color: mix($color-light-on, $color-light-off, 10);
      }
      &2 {
        background-color: mix($color-light-on, $color-light-off, 20);
      }
      &3 {
        background-color: mix($color-light-on, $color-light-off, 30);
      }
      &4 {
        background-color: mix($color-light-on, $color-light-off, 40);
      }
      &5 {
        background-color: mix($color-light-on, $color-light-off, 50);
      }
      &6 {
        background-color: mix($color-light-on, $color-light-off, 60);
      }
      &7 {
        background-color: mix($color-light-on, $color-light-off, 70);
      }
      &8 {
        background-color: mix($color-light-on, $color-light-off, 80);
      }
      &9 {
        background-color: mix($color-light-on, $color-light-off, 90);
      }
      &10 {
        background-color: $color-light-on;
      }
    }
  }
}
</style>
