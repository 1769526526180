<template>
  <div class="page">
    <div class="content">
      <h1>Confirm Payment</h1>
      <p>We are ready to help you join Spotee Connect.<br />Please click below to navigate to our checkout.</p>
      <p>
        <CheckboxInput name="agreement" v-model="agreement" :disabled="$store.getters['StripePayment/busy']">
          I agree to Spotee Connect’s <a @click="$router.push('/terms')">Terms of Use</a>
        </CheckboxInput>
      </p>
      <div class="actions">
        <Button
          v-bind="Functions.Presets.Button.Full"
          text="Proceed"
          :disabled="$store.getters['StripePayment/busy'] || !agreement"
          @click="checkout"
        />
        <span class="or">or navigate</span>
        <Button v-bind="Functions.Presets.Button.InlineLink" text="back to login" to="/logout" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import Functions from "@/functions";
import Button from "@/components/atoms/Button.vue";
import CheckboxInput from "@/components/atoms/CheckboxInput.vue";
export default {
  name: "PendingPayment",
  components: {
    Button,
    CheckboxInput
  },
  setup() {
    const store = useStore();
    const agreement = ref(false);

    const checkout = () => {
      store.dispatch("StripePayment/Checkout").then(res => {
        if (res.data.checkoutUrl) {
          window.location = res.data.checkoutUrl;
        }
      });
    };

    return {
      Functions,
      agreement,
      checkout
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  @include flexCentered(column);
  @include golfBackground;

  .content {
    z-index: z("front");
    @include maxWidth($size-breakpoint-mobile);
    text-align: left;
    padding: addSpace(6) addSpace(4);

    .actions {
      @include flexCentered;

      .or {
        margin: addSpace(2);
      }
    }
  }
}
</style>
