<template>
  <div class="lux" :class="{ zero: validScore == 0 }" :style="cssVars">
    <div class="score" v-text="displayScore"></div>
    <div class="spot" :class="spotScore"></div>
  </div>
</template>

<script>
import { computed } from "vue";
import Functions from "@/functions";
export default {
  name: "Lux",
  props: {
    score: { type: [String, Number], default: 0 },
    size: { type: [String, Number], default: "2em" }
  },
  setup(props) {
    const validScore = computed(() => {
      return Math.max(Math.min(parseFloat(props.score) || 0, 10), 0);
    });
    const displayScore = computed(() => {
      return validScore.value ? Functions.Formatting.addCommas(validScore.value, 0) : "-";
    });
    const spotScore = computed(() => {
      return 100 - Math.round(validScore.value - 1) * 11.111 + "%";
    });
    const cssVars = computed(() => {
      return {
        "--score": validScore.value,
        "--size": props.size,
        "--spotScore": spotScore.value
      };
    });

    return {
      validScore,
      displayScore,
      spotScore,
      cssVars
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.lux {
  @include transition;
  @include flexCentered;
  width: var(--size);
  height: var(--size);
  position: relative;
  color: lighten($color-black, 20%);
  background: $color-white;
  border-radius: 99em;
  overflow: hidden;

  .score {
    @include transition;
    position: relative;
    z-index: z("front");
    font-weight: 400;
  }

  .spot {
    @include transition;
    position: absolute;
    z-index: z("flat");
    height: 100%;
    width: 100%;
    border-radius: 99em;
    background: $color-light;
    transform: translateY(var(--spotScore));
  }
}
</style>
