<template>
  <div class="login">
    <SpotlightShine />
    <div class="content">
      <img alt="Soptee" src="@/assets/logo/spotee+color.svg" />
      <h1 v-t="'project.slogan'" />
      <form v-on:submit.prevent="login" class="common-form" :class="{ 'common-form-loading': busy }">
        <div class="form-content" :class="{ 'common-disabled': busy }">
          <component
            :is="f.component"
            v-for="(f, k) in fields"
            :key="f.key || k"
            v-bind="f.preset"
            v-model="f.value"
            color="blue"
          />
          <transition name="maxH">
            <div class="common-form-error" v-if="formError" v-t="formError" />
          </transition>

          <div class="form-actions">
            <Button v-bind="Functions.Presets.Button.Submit" text="Login" :disabled="!canSubmit" @click="login" />
            <Button v-bind="Functions.Presets.Button.LinkLight" text="forgot password?" to="/forgot-password" />
          </div>
        </div>
      </form>

      <div class="more">
        <Button v-bind="Functions.Presets.Button.HollowSuccess" text="become a member" to="/register" />
      </div>

      <div class="disclaimer">
        Spotee Pty Ltd ABN 55 637 623 940 is a corporate authorised representative (CARN 001283189) of Daylight
        Financial Group Pty Ltd ABN 77 633 984 773 AFSL 521404. Copyright {{ new Date().getFullYear() }}.
        <br />
        <br />
        <Disclaimer />
      </div>
    </div>
  </div>
</template>

<script>
import { markRaw } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Functions from "@/functions";
import { useForm } from "@/components/renderless/form";
import Button from "@/components/atoms/Button.vue";
import Disclaimer from "@/components/atoms/Disclaimer.vue";
import Input from "@/components/atoms/Input.vue";
import SpotlightShine from "@/components/atoms/SpotlightShine.vue";
export default {
  name: "Login",
  components: {
    Button,
    Disclaimer,
    Input,
    SpotlightShine
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const formData = useForm([
      {
        key: "username",
        component: markRaw(Input),
        preset: { ...Functions.Presets.Field.DisplayName, label: "Email" },
        value: ""
      },
      {
        key: "password",
        component: markRaw(Input),
        preset: Functions.Presets.Field.Password,
        value: ""
      }
    ]);
    const login = () => {
      if (!formData.canSubmit.value) {
        return;
      }
      formData.formError.value = "";
      formData.busy.value = true;
      store
        .dispatch("User/login", formData.currentData.value)
        .then(
          () => {
            router.push({ path: store.getters["User/roleHome"] });
          },
          () => {
            formData.formError.value = "common.validation.login_fail";
            formData.busy.value = false;
          }
        )
        .finally(() => {
          formData.busy.value = false;
        });
    };
    return {
      Functions,
      busy: formData.busy,
      formError: formData.formError,
      canSubmit: formData.canSubmit,
      currentData: formData.currentData,
      fields: formData.fields,
      isFormValid: formData.isFormValid,
      login
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.login {
  height: 100%;
  @include flexCentered(column);
  color: $color-white;
  background-color: $color-blue;
  background-image: radial-gradient(rgba($color-blue, 0) 30%, rgba($color-blue, 1)), url("../../assets/bits/golf.png");
  background-position: center;

  .content {
    z-index: z("front");
    @include maxWidth($size-breakpoint-mobile);
    text-align: left;
    padding: addSpace(6) addSpace(4);

    h1 {
      color: inherit;
      text-align: center;
      font-weight: 300;
      margin: addSpace(-5) 0 addSpace(12) 0;
      b {
        font-weight: 700;
      }
    }

    .form-actions {
      @include flexCentered();
      .button + .button {
        margin-left: auto;
      }
    }

    .more {
      @include flexCentered();
      margin: addSpace(10) 0 addSpace(4) 0;
      .button {
        flex-grow: 1;
        color: $color-success;
      }
    }

    .disclaimer {
      font-size: $text-small;
      text-align: justify;

      :deep(.disclaimer) {
        background: transparent;
        padding: 0;
        color: $color-primary;
      }
    }
  }
}
</style>
