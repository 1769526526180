<template>
  <div class="payment" v-if="payment">
    <div class="left">
      <div class="title">
        {{ payment.lineItem }}
      </div>
      <div class="on">
        {{ Functions.Dates.friendlyDateTimeText(payment.paidOn || payment.appliedOn || payment.submittedOn) }}<br />
      </div>
    </div>
    <div class="right">
      <div class="price">
        {{ Functions.Formatting.currency(payment.amountTotalCents / 100) }}
      </div>
      <div class="status">
        <Pill :color="payment.status == 'paid' ? 'success' : 'warning'">{{ payment.status }}</Pill>
      </div>
    </div>
    <div class="invoice" :class="{ 'common-disabled': !payment.invoicePdfUrl }" @click="openInvoice(payment)">
      <Icon icon="doc" /> <span v-if="!payment.invoicePdfUrl">No </span>Invoice
    </div>
  </div>
</template>

<script>
import Functions from "@/functions";
import Icon from "@/components/atoms/Icon.vue";
import Pill from "@/components/atoms/Pill.vue";
export default {
  name: "PaymentCard",
  components: {
    Icon,
    Pill
  },
  props: {
    payment: { type: Object, default: () => {} }
  },
  setup() {
    const openInvoice = p => {
      window.open(p.invoicePdfUrl, "_blank");
    };
    return {
      Functions,
      openInvoice
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.payment {
  @include flexCentered;
  flex-wrap: wrap;
  @include edge(15px);
  width: 100%;
  margin: addSpace(2) 0;
  padding: addSpace(2);
  border: 1px solid $color-light-gray;

  .left {
    @include flexCentered(column);
    align-items: flex-start;
  }
  .right {
    @include flexCentered(column);
    margin-left: auto;
  }

  .title {
    font-weight: 600;
    color: $color-blue;
  }
  .on {
    font-size: $text-small;
    font-style: italic;
  }

  .invoice {
    width: 100%;
    margin-top: addSpace(2);
    cursor: pointer;
    .icon {
      color: $color-blue;
      width: 3em;
      height: 3em;
    }
  }
}
</style>
