<template>
  <div class="page">
    <div class="user">
      <Icon class="profile" icon="profile" />
      {{ userDataValue("firstName") }}
      {{ userDataValue("lastName") }}
    </div>

    <div class="content"><span class="label">Email:</span> {{ userDataValue("email") }}</div>
    <div class="content">
      <span class="label">Phone:</span> {{ userDataValue("phone") || "-" }} |
      <Button
        v-bind="Functions.Presets.Button.InlineLink"
        text="Edit"
        icon="arrow-right"
        iconPosition="right"
        to="/profile/edit/phone"
      />
    </div>
    <div class="content">
      <span class="label">Nickname:</span> {{ userDataValue("displayName") || "-" }} |
      <Button
        v-bind="Functions.Presets.Button.InlineLink"
        text="Edit"
        icon="arrow-right"
        iconPosition="right"
        to="/profile/edit/displayName"
      />
    </div>
    <div class="content">
      <span class="label">User since:</span> {{ Functions.Dates.friendlyDateTimeText(userDataValue("createdOn")) }}
    </div>
    <div class="content">
      <span class="label">Password:</span>
      <Button
        v-bind="Functions.Presets.Button.InlineLink"
        text="Change password"
        icon="arrow-right"
        iconPosition="right"
        to="/change-password"
      />
    </div>

    <div class="content">
      <span class="label">Licence expiry:</span>
      {{ userDataValue("licenseExpiryDays") }} days
      <div class="common-margin-auto-left">
        <transition name="maxH">
          <div class="common-color-error" v-if="!userDataValue('isSubscribed')">
            No subscription |
            <Button
              v-bind="Functions.Presets.Button.Full"
              text="Subscribe"
              icon="arrow-right"
              iconPosition="right"
              :disabled="$store.getters['StripePayment/busy']"
              @click="checkout"
            />
          </div>
        </transition>

        <transition name="maxH">
          <Button
            v-if="userDataValue('isSubscribed')"
            v-bind="Functions.Presets.Button.HollowError"
            text="Cancel subscription"
            icon="delete"
            iconPosition="right"
            @click="confirmCancel = true"
          />
        </transition>

        <Modal :show="confirmCancel" class="confirmCancel">
          <template v-slot:body>
            <div class="title">
              Are you sure you want to cancel your subscription?
            </div>
            <div class="text">
              We're sorry to see you go. Your subscription will remain active until
              {{ Functions.Dates.dateText(userDataValue("licenseExpiry")) }}. You can re-instate your subscription at
              any time.
            </div>
          </template>

          <template v-slot:footer>
            <Button
              v-bind="Functions.Presets.Button.HollowGray"
              :disabled="$store.getters['StripePayment/busy']"
              text="No thanks"
              @click="confirmCancel = false"
            />
            <Button
              v-bind="Functions.Presets.Button.FullDelete"
              :disabled="$store.getters['StripePayment/busy']"
              text="Yes cancel"
              icon="delete"
              @click="cancelSubscription"
            />
            <LoadingBar class="loading" :loading="$store.getters['StripePayment/busy']" :transparent="true" />
          </template>
        </Modal>
      </div>
    </div>
    <div class="content">
      <div class="payments">
        <span class="label">Payments:</span>
        <PaymentCard v-for="payment in $store.getters['StripePayment/payments']" :key="payment.id" :payment="payment" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { mapGetters, useStore } from "vuex";
import Functions from "@/functions";
import Button from "@/components/atoms/Button.vue";
import Icon from "@/components/atoms/Icon.vue";
import LoadingBar from "@/components/atoms/LoadingBar.vue";
import Modal from "@/components/atoms/Modal.vue";
import PaymentCard from "@/components/atoms/PaymentCard.vue";
export default {
  name: "Profile",
  components: {
    Button,
    Icon,
    LoadingBar,
    Modal,
    PaymentCard
  },
  computed: {
    ...mapGetters("User", ["userDataValue"])
  },
  setup() {
    const store = useStore();
    const confirmCancel = ref(false);

    const cancelSubscription = () => {
      store.dispatch("StripePayment/CancelSubscription").finally(() => {
        confirmCancel.value = false;
        setTimeout(() => {
          store.dispatch("User/getCurrent");
        }, 2000);
      });
    };

    const checkout = () => {
      store.dispatch("StripePayment/Checkout").then(res => {
        if (res.data.checkoutUrl) {
          window.location = res.data.checkoutUrl;
        }
      });
    };

    onMounted(() => {
      store.dispatch("StripePayment/GetPayments");
    });

    return {
      Functions,
      confirmCancel,
      cancelSubscription,
      checkout
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  @include flexCentered(column);
  justify-content: flex-start;
  padding: 0 addSpace(2) addSpace(2) addSpace(2);

  .user {
    @include maxWidth($size-breakpoint-phablet);
    color: $color-blue;
    background: rgba($color-blue, 0.1);
    margin-bottom: addSpace(4);
    border-bottom-right-radius: 30px;
    font-size: $text-lead;
    font-weight: 600;
    .icon {
      width: 15rem;
      height: 15rem;
      opacity: 0.15;
      margin: -1rem -4rem -2rem -3rem;
    }
  }

  .content {
    @include flexCentered;
    @include maxWidth($size-breakpoint-phablet);
    justify-content: flex-start;
    gap: addSpace(2);
    padding: addSpace(6) addSpace(4);
    & + .content {
      border-top: 1px dashed $color-light-gray;
    }

    .label {
      font-size: $text-small;
      color: $color-dark-gray;
    }
  }

  .payments {
    width: 100%;
  }

  .confirmCancel {
    :deep(.modal-body) {
      @include maxWidth($size-breakpoint-medium);
      padding: addSpace(4);
      .title {
        color: $color-error;
        font-weight: 600;
        margin: addSpace(4) 0;
      }
    }
    .loading-bar {
      position: absolute;
      width: 100%;
      bottom: 0;
    }
  }
}
</style>
