<template>
  <div class="spotlight" :style="spotlightStyle"></div>
</template>

<script>
import { reactive, ref, computed, onMounted, onBeforeUnmount } from "vue";
export default {
  name: "SpotlightShine",
  setup() {
    const interval = ref(null);
    const deviation = reactive({
      x: 0.5,
      y: 0.5
    });
    const spotlightStyle = computed(() => {
      return {
        top: 25 * deviation.y + "vh",
        left: 30 * deviation.x + "vh"
      };
    });

    const move = () => {
      deviation.x = Math.random();
      deviation.y = Math.random();
    };

    onMounted(() => {
      move();
      interval.value = setInterval(move, 2000);
    });

    onBeforeUnmount(() => {
      clearInterval(interval.value);
    });

    return {
      spotlightStyle
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.spotlight {
  @include transition(2s);
  z-index: z("flat");
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 20vh;
  margin-left: 10vw;
  width: 40vh;
  height: 40vh;
  background: $color-white;
  box-shadow: 0 0 5vh 5vh $color-white;
  border-radius: 99rem;
  mix-blend-mode: soft-light;
  opacity: 0.5;
}
</style>
