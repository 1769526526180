import { ref, computed, watch } from "vue";
import Functions from "@/functions";
import _ from "lodash";

export const useForm = data => {
  const busy = ref(false);
  const formError = ref("");
  const fields = ref(data);
  const isFormValid = ref(false);
  const fieldsCount = computed(() => {
    return fields.value.length;
  });
  const canSubmit = computed(() => {
    return isFormValid.value && !busy.value;
  });
  const currentData = computed(() => {
    return fields.value.reduce((result, field) => {
      result[field.key] = field.value;
      return result;
    }, {});
  });

  const validateForm = () => {
    let list = fields.value.reduce((result, field) => {
      result.push(Functions.Utils.toPromise(field.preset.validation)(field.value));
      return result;
    }, []);
    Promise.all(list).then(values => {
      isFormValid.value = values.reduce((r, f) => r && f, true);
    });
  };

  watch(
    () => _.cloneDeep(fields.value),
    () => {
      validateForm();
    }
  );

  return {
    busy,
    canSubmit,
    currentData,
    formError,
    fields,
    fieldsCount,
    isFormValid,
    validateForm
  };
};
