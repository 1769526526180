<template>
  <div class="page">
    <div class="content">
      <h1>Confirm Email</h1>
      <p>Please confirm your email address, click below to receive a new email.</p>
      <div class="actions">
        <Button
          v-bind="Functions.Presets.Button.Full"
          text="Re-send activation email"
          :disabled="$store.getters['StripePayment/busy']"
          @click="requestEmailValidation"
        />
        <span class="or">or navigate</span>
        <Button v-bind="Functions.Presets.Button.InlineLink" text="back to login" to="/logout" />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Functions from "@/functions";
import Button from "@/components/atoms/Button.vue";
export default {
  name: "PendingActivation",
  components: {
    Button
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const requestEmailValidation = () => {
      store
        .dispatch("User/requestEmailValidation", {
          email: store.getters["User/userData"].email
        })
        .then(() => {
          router.push({ path: "/request-activation-success" });
        });
    };

    return {
      Functions,
      requestEmailValidation
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  @include flexCentered(column);
  @include golfBackground;

  .content {
    z-index: z("front");
    @include maxWidth($size-breakpoint-mobile);
    text-align: left;
    padding: addSpace(6) addSpace(4);

    .actions {
      @include flexCentered;

      .or {
        margin: addSpace(2);
      }
    }
  }
}
</style>
