<template>
  <div class="page">
    <div class="content">
      <h1>Registration success</h1>
      <p>Thank you for registering, You will receive an activation link at the provided email address.</p>
      <p>
        Once you have confirmed your email address, you can then navigate to the
        <Button v-bind="Functions.Presets.Button.InlineLink" text="login page" to="/login" />
        to use the app.
      </p>
    </div>
  </div>
</template>

<script>
import Functions from "@/functions";
import Button from "@/components/atoms/Button.vue";
export default {
  name: "RegisterSuccess",
  components: {
    Button
  },
  props: {
    key: { type: String, default: null },
    title: { type: String, default: null },
    description: { type: String, default: null },
    confirm: { type: Object, default: null },
    cancel: { type: Object, default: null }
  },
  setup() {
    return {
      Functions
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  @include flexCentered(column);
  @include golfBackground;

  .content {
    z-index: z("front");
    @include maxWidth($size-breakpoint-mobile);
    text-align: left;
    padding: addSpace(6) addSpace(4);
  }
}
</style>
