<template>
  <div class="page">
    <div class="content">
      <h1><img alt="Soptee" src="@/assets/logo/spotee-min.svg" /> Activating</h1>
      <LoadingBar class="loading" :loading="true" :transparent="true" />
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import LoadingBar from "@/components/atoms/LoadingBar.vue";
export default {
  name: "Activate",
  props: {
    token: { type: String, default: null }
  },
  components: {
    LoadingBar
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const activate = () => {
      store
        .dispatch("User/validateEmail", { token: props.token })
        .then(
          () => {
            router.push({ path: "/activate-success" });
          },
          () => {
            router.push({ path: "/activate-fail" });
          }
        )
        .catch(() => {
          router.push({ path: "/activate-fail" });
        });
    };

    onMounted(() => {
      activate();
    });
    return {
      activate
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  height: 100%;
  @include flexCentered(column);
  @include golfBackground;

  .content {
    z-index: z("front");
    @include maxWidth($size-breakpoint-mobile);
    text-align: left;
    padding: addSpace(6) addSpace(4);

    h1 {
      font-weight: 300;
      margin: addSpace(-10) 0 addSpace(12) 0;
      img {
        position: relative;
        top: 50px;
        height: 120px;
      }
    }
  }
}
</style>
