<template>
  <div class="register">
    <div class="content">
      <h1><img alt="Soptee" src="@/assets/logo/spotee-min.svg" /> Create an account</h1>
      <form v-on:submit.prevent="register" class="common-form" :class="{ 'common-form-loading': busy }">
        <div class="form-content" :class="{ 'common-disabled': busy }">
          <component :is="f.component" v-for="(f, k) in fields" :key="f.key || k" v-bind="f.preset" v-model="f.value" />

          <transition name="maxH">
            <div class="common-form-error" v-if="formError" v-t="formError" />
          </transition>

          <div class="form-actions">
            <Button
              v-bind="Functions.Presets.Button.Submit"
              text="Let's get started!"
              :disabled="!canSubmit"
              @click="register"
            />
            <Button v-bind="Functions.Presets.Button.Link" text="login" to="/login" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { markRaw } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Functions from "@/functions";
import { useForm } from "@/components/renderless/form";
import Button from "@/components/atoms/Button.vue";
import Input from "@/components/atoms/Input.vue";
export default {
  name: "Register",
  components: {
    Button,
    Input
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const formData = useForm([
      //ACCOUNT
      {
        key: "firstName",
        component: markRaw(Input),
        preset: Object.assign({ ...Functions.Presets.Field.Field }, { label: "Name" }),
        value: ""
      },
      {
        key: "lastName",
        component: markRaw(Input),
        preset: Object.assign({ ...Functions.Presets.Field.Field }, { label: "Surname" }),
        value: ""
      },
      {
        key: "email",
        component: markRaw(Input),
        preset: {
          ...Functions.Presets.Field.Email,
          validation: v => {
            if (!Functions.Validations.isEmailValid(v)) {
              return false;
            } else {
              return store.dispatch("User/checkEmailInUse", formData.currentData.value).then(
                response => {
                  return response.data.inUse ? "common.validation.email_in_use" : true;
                },
                () => {
                  return "common.validation.generic";
                }
              );
            }
          }
        },
        value: ""
      },
      {
        key: "phone",
        component: markRaw(Input),
        preset: Functions.Presets.Field.Mobile,
        value: ""
      },
      //USER
      {
        key: "displayName",
        component: markRaw(Input),
        preset: {
          ...Functions.Presets.Field.DisplayName,
          validation: v => {
            if (v.length < 2) {
              return false;
            } else {
              return store.dispatch("User/checkDisplayNameInUse", formData.currentData.value).then(
                response => {
                  return response.data.inUse ? "common.validation.nickname_in_use" : true;
                },
                () => {
                  return "common.validation.generic";
                }
              );
            }
          }
        },
        value: ""
      },
      {
        key: "password",
        component: markRaw(Input),
        preset: Functions.Presets.Field.Password,
        value: ""
      }
    ]);
    const register = () => {
      if (!formData.canSubmit.value) {
        return;
      }
      formData.formError.value = "";
      formData.busy.value = true;
      store
        .dispatch("User/register", formData.currentData.value)
        .then(
          () => {
            store.dispatch("User/requestEmailValidation", {
              email: formData.currentData.value.email
            });
            router.push({ path: "/register-success" });
          },
          () => {
            formData.formError.value = "common.validation.generic";
            formData.busy.value = false;
          }
        )
        .finally(() => {
          formData.busy.value = false;
        });
    };
    return {
      Functions,
      busy: formData.busy,
      formError: formData.formError,
      canSubmit: formData.canSubmit,
      currentData: formData.currentData,
      fields: formData.fields,
      isFormValid: formData.isFormValid,
      register
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.register {
  height: 100%;
  @include flexCentered(column);
  @include golfBackground;

  .content {
    z-index: z("front");
    @include maxWidth($size-breakpoint-mobile);
    text-align: left;
    padding: addSpace(6) addSpace(4);

    h1 {
      font-weight: 300;
      margin: addSpace(-10) 0 addSpace(12) 0;
      img {
        position: relative;
        top: 50px;
        height: 120px;
      }
    }

    form {
      h2 {
        color: darken($color-blue, 10%);
        background: linear-gradient(to right, $color-white, transparent 55%);
        border-radius: 9em;
        padding: addSpace(1) addSpace(2) 0 addSpace(2);
        margin-left: addSpace(-2);
      }
      .col2 {
        display: inline-block;
        width: calc(50% - #{addSpace(1)});
        & + .col2 {
          margin-left: addSpace(2);
        }
      }
    }

    .form-content {
      :deep(input) {
        @include standardShadow;
      }
    }

    .form-actions {
      @include flexCentered();
      .button + .button {
        margin-left: auto;
      }
    }
  }
}
</style>
