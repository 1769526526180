<template>
  <div class="page">
    <div class="content">
      <h1>Activation failed</h1>
      <p>Something went wrong, You are probably useing an old link, try to login with your credentials.</p>
      <p>
        Please navigate to the
        <Button v-bind="Functions.Presets.Button.InlineLink" text="login page" to="/login" />
        to use the app.
      </p>
    </div>
  </div>
</template>

<script>
import Functions from "@/functions";
import Button from "@/components/atoms/Button.vue";
export default {
  name: "ActivateFail",
  components: {
    Button
  },
  setup() {
    return {
      Functions
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  @include flexCentered(column);
  @include golfBackground;

  .content {
    z-index: z("front");
    @include maxWidth($size-breakpoint-mobile);
    text-align: left;
    padding: addSpace(6) addSpace(4);
  }
}
</style>
