<template>
  <div class="page">
    <div class="content">
      <h1 v-if="titleKey" v-t="titleKey" />
      <p v-if="descriptionKey" v-html="descriptionKey" />
      <Button
        v-bind="Functions.Presets.Button.InlineLink"
        text="go to login page"
        icon="arrow-right"
        iconPosition="right"
        to="/login"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import Functions from "@/functions";
import Button from "@/components/atoms/Button.vue";
export default {
  name: "Success",
  components: {
    Button
  },
  props: {
    key: { type: String, default: null },
    title: { type: String, default: null },
    description: { type: String, default: null },
    confirm: { type: Object, default: null },
    cancel: { type: Object, default: null }
  },
  setup(props) {
    const router = useRouter();
    const i18n = useI18n();
    const pageKey = computed(() => props.key || router.currentRoute.value.name);
    const titleKey = computed(() => {
      let derived = `page.${pageKey.value}.title`;
      let translation = i18n.te(derived) ? i18n.t(derived) : null;
      return props.title || translation;
    });
    const descriptionKey = computed(() => {
      let derived = `page.${pageKey.value}.description`;
      let translation = i18n.te(derived) ? i18n.t(derived) : null;
      return props.description || translation;
    });

    return {
      Functions,
      pageKey,
      titleKey,
      descriptionKey
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  @include flexCentered(column);
  @include golfBackground;

  .content {
    z-index: z("front");
    @include maxWidth($size-breakpoint-mobile);
    text-align: left;
    padding: addSpace(6) addSpace(4);
  }
}
</style>
